
import { Vue, Component } from 'vue-property-decorator'
import { DicInfo } from '@/types/common'
import { Detail } from '@/types/healthLife'

@Component
export default class HealthLife extends Vue {
  private lifeTypeList: Array<DicInfo> = []
  private searchInfo = {
    projectName: '',
    lifeType: '',
    articleTheme: ''
  }

  private page = 1
  private size = 10
  private total = 0
  private loading = false
  private tableData: Detail[] = []

  created () {
    this.getLifeTypeList()
    this.getData()
  }

  // 查询
  searchData () {
    this.page = 1
    this.getData()
  }

  // 获取养生类型列表
  getLifeTypeList () {
    this.$axios.get(this.$apis.common.selectDicByList, {
      dicType: 'lifeType'
    }).then(res => {
      this.lifeTypeList = res.lifeType || []
    })
  }

  getData () {
    this.loading = true
    this.$axios.get<{ total: number; list: Array<Detail> }>(this.$apis.healthLife.selectHealthLifeByPage, {
      page: this.page,
      size: this.size,
      ...this.searchInfo
    }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  // 新增
  onAdd () {
    this.$router.push({ path: '/healthLife/add' })
  }

  onDetail (id: string) {
    this.$router.push({
      name: 'healthLifeDetail',
      params: { id }
    })
  }

  onDelete (lifeId: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.healthLife.deleteHealthLife, { lifeId }).then(() => {
        this.$message.success('删除成功')
        this.searchData()
      })
    }).catch(() => {
      console.log('已取消')
    })
  }
}
